<template>
  <h4 class="font-semibold text-2xl mt-12">
    Enteignungen in Deutschland von 2009 bis Mai 2020
  </h4>
  <p class="text-gray-500">
    im Rahmen des Baus von Autobahnen und Bundesstraßen
  </p>
  <div id="column-chart" style="height: 70vh; width: 59vw"></div>
  <div
    class="text-center text-base text-gray-500 border-b border-gray-300 pb-12"
  >
    Quelle: Bundesministerium für Verkehr und digitale Infrastruktur
    <span class="whitespace-nowrap"
      >(<a
        href="/Antwort-BMVI-Enteignungen-2009-05_2020.pdf"
        target="_blank"
        title="Auflistung des BMVI über Enteignungsverfahren in Deutschland von 2009 bis 2020"
        class="text-primary underline hover:no-underline"
        download
        >PDF Download</a
      >)</span
    >
  </div>
</template>

<script lang="ts">
import { Options, Vue } from "vue-class-component";
// Chart
import {
  ColumnChart,
  ColumnChartOptions,
  BoxSeriesData,
} from "@toast-ui/chart";
import { IAnnualTotalExpropriations } from "src/interfaces/annual-total-expropriations.interface";

@Options({
  props: {
    annualTotals: Array,
  },
})
export default class FactsChart extends Vue {
  annualTotals!: IAnnualTotalExpropriations[];
  chart!: ColumnChart;

  mounted(): void {
    this.initChart();
  }

  unmounted(): void {
    this.chart.destroy();
  }

  public get annualTotalsAverage(): number {
    const amount = this.annualTotals?.map(
      (annualTotal: IAnnualTotalExpropriations) => annualTotal.total
    ).length;
    const sum = this.annualTotals?.reduce(
      (totalsSum: number, currentTotal: IAnnualTotalExpropriations) => {
        return (totalsSum += currentTotal.total);
      },
      0
    );
    return Math.floor(sum / amount);
  }

  private initChart(): void {
    const el = document.getElementById("column-chart") as HTMLElement;
    const categories = this.annualTotals?.map(
      (annualTotal: IAnnualTotalExpropriations) => `${annualTotal.year}`
    );
    const finished = {
      name: "Abgeschlossen",
      data: this.annualTotals?.map(
        (annualTotal: IAnnualTotalExpropriations) => annualTotal.finished
      ),
    };
    const ongoing = {
      name: "Nicht abgeschlossen",
      data: this.annualTotals?.map(
        (annualTotal: IAnnualTotalExpropriations) => annualTotal.ongoing
      ),
    };
    const series = [finished, ongoing];
    const data = {
      categories,
      series,
    } as BoxSeriesData;
    const options = {
      chart: {
        width: "auto",
        height: "auto",
      },
      series: {
        stack: {
          type: "normal",
        },
        dataLabels: {
          visible: true,
          formatter: (value) => `${value}`,
          anchor: "center",
          stackTotal: {
            visible: true,
            formatter: (value) => `Gesamt ${value}`,
          },
        },
      },
      responsive: {
        animation: {
          duration: 600,
        },
        rules: [
          {
            condition: ({ width: w }) => {
              return w <= 1200;
            },
            options: {
              legend: {
                align: "bottom",
              },
            },
          },
          {
            condition: ({ width: w }) => {
              return w < 690;
            },
            options: {
              series: {
                dataLabels: {
                  stackTotal: {
                    visible: false,
                  },
                },
              },
            },
          },
          {
            condition: ({ width: w }) => {
              return w <= 380;
            },
            options: {
              exportMenu: {
                visible: false,
              },
            },
          },
        ],
      },
      theme: {
        series: {
          colors: ["#00B3CD", "#F7A600"],
        },
      },
      usageStatistics: false,
    } as ColumnChartOptions;
    this.chart = new ColumnChart({ el, data, options });
  }
}
</script>

<style scoped>
#column-chart {
  @apply container;
  @apply mx-auto;
}
@media screen and (max-width: 768px) {
  #column-chart {
    width: 90vw !important;
  }
}
@media screen and (min-width: 1749px) {
  #column-chart {
    width: 55vw !important;
  }
}
</style>
