
import { Options, Vue } from "vue-class-component";
// Chart
import {
  ColumnChart,
  ColumnChartOptions,
  BoxSeriesData,
} from "@toast-ui/chart";
import { IAnnualTotalExpropriations } from "src/interfaces/annual-total-expropriations.interface";

@Options({
  props: {
    annualTotals: Array,
  },
})
export default class FactsChart extends Vue {
  annualTotals!: IAnnualTotalExpropriations[];
  chart!: ColumnChart;

  mounted(): void {
    this.initChart();
  }

  unmounted(): void {
    this.chart.destroy();
  }

  public get annualTotalsAverage(): number {
    const amount = this.annualTotals?.map(
      (annualTotal: IAnnualTotalExpropriations) => annualTotal.total
    ).length;
    const sum = this.annualTotals?.reduce(
      (totalsSum: number, currentTotal: IAnnualTotalExpropriations) => {
        return (totalsSum += currentTotal.total);
      },
      0
    );
    return Math.floor(sum / amount);
  }

  private initChart(): void {
    const el = document.getElementById("column-chart") as HTMLElement;
    const categories = this.annualTotals?.map(
      (annualTotal: IAnnualTotalExpropriations) => `${annualTotal.year}`
    );
    const finished = {
      name: "Abgeschlossen",
      data: this.annualTotals?.map(
        (annualTotal: IAnnualTotalExpropriations) => annualTotal.finished
      ),
    };
    const ongoing = {
      name: "Nicht abgeschlossen",
      data: this.annualTotals?.map(
        (annualTotal: IAnnualTotalExpropriations) => annualTotal.ongoing
      ),
    };
    const series = [finished, ongoing];
    const data = {
      categories,
      series,
    } as BoxSeriesData;
    const options = {
      chart: {
        width: "auto",
        height: "auto",
      },
      series: {
        stack: {
          type: "normal",
        },
        dataLabels: {
          visible: true,
          formatter: (value) => `${value}`,
          anchor: "center",
          stackTotal: {
            visible: true,
            formatter: (value) => `Gesamt ${value}`,
          },
        },
      },
      responsive: {
        animation: {
          duration: 600,
        },
        rules: [
          {
            condition: ({ width: w }) => {
              return w <= 1200;
            },
            options: {
              legend: {
                align: "bottom",
              },
            },
          },
          {
            condition: ({ width: w }) => {
              return w < 690;
            },
            options: {
              series: {
                dataLabels: {
                  stackTotal: {
                    visible: false,
                  },
                },
              },
            },
          },
          {
            condition: ({ width: w }) => {
              return w <= 380;
            },
            options: {
              exportMenu: {
                visible: false,
              },
            },
          },
        ],
      },
      theme: {
        series: {
          colors: ["#00B3CD", "#F7A600"],
        },
      },
      usageStatistics: false,
    } as ColumnChartOptions;
    this.chart = new ColumnChart({ el, data, options });
  }
}
